import Papa from "papaparse"

const SORTING_ORDER = [
  "INVOICE_DATE",
  "INVOICE_NUMBER",
  "CLIENT_ID",
  "LAW_FIRM_MATTER_ID",
  "INVOICE_TOTAL",
  "BILLING_START_DATE",
  "BILLING_END_DATE",
  "INVOICE_DESCRIPTION",
  "LINE_ITEM_NUMBER",
  "EXP/FEE/INV_ADJ_TYPE",
  "LINE_ITEM_NUMBER_OF_UNITS",
  "LINE_ITEM_ADJUSTMENT_AMOUNT",
  "LINE_ITEM_TOTAL",
  "LINE_ITEM_DATE",
  "LINE_ITEM_TASK_CODE",
  "LINE_ITEM_EXPENSE_CODE",
  "LINE_ITEM_ACTIVITY_CODE",
  "TIMEKEEPER_ID",
  "LINE_ITEM_DESCRIPTION",
  "LAW_FIRM_ID",
  "LINE_ITEM_UNIT_COST",
  "TIMEKEEPER_NAME",
  "TIMEKEEPER_CLASSIFICATION",
  "CLIENT_MATTER_ID",
  "PO_NUMBER",
  "CLIENT_TAX_ID",
  "MATTER_NAME",
  "INVOICE_TAX_TOTAL",
  "INVOICE_NET_TOTAL",
  "INVOICE_CURRENCY",
  "TIMEKEEPER_LAST_NAME",
  "TIMEKEEPER_FIRST_NAME",
  "ACCOUNT_TYPE",
  "LAW_FIRM_NAME",
  "LAW_FIRM_ADDRESS_1",
  "LAW_FIRM_ADDRESS_2",
  "LAW_FIRM_CITY",
  "LAW_FIRM_STATEorREGION",
  "LAW_FIRM_POSTCODE",
  "LAW_FIRM_COUNTRY",
  "CLIENT_NAME",
  "CLIENT_ADDRESS_1",
  "CLIENT_ADDRESS_2",
  "CLIENT_CITY",
  "CLIENT_STATEorREGION",
  "CLIENT_POSTCODE",
  "CLIENT_COUNTRY",
  "LINE_ITEM_TAX_RATE",
  "LINE_ITEM_TAX_TOTAL",
  "LINE_ITEM_TAX_TYPE",
  "INVOICE_REPORTED_TAX_TOTAL",
  "INVOICE_TAX_CURRENCY",
]

// Define the types for the columns
export type InvoiceData = {
  INVOICE_DATE: string
  INVOICE_NUMBER: string
  CLIENT_ID: string
  LAW_FIRM_MATTER_ID: string
  INVOICE_TOTAL: string
  BILLING_START_DATE: string
  BILLING_END_DATE: string
  INVOICE_DESCRIPTION: string
  LAW_FIRM_ID: string
  CLIENT_TAX_ID: string
  CLIENT_MATTER_ID: string
  CLIENT_NAME: string
  CLIENT_ADDRESS_1: string
  CLIENT_ADDRESS_2: string
  CLIENT_CITY: string
  CLIENT_STATEorREGION: string
  CLIENT_POSTCODE: string
  CLIENT_COUNTRY: string
  PO_NUMBER: string
  MATTER_NAME: string
  INVOICE_TAX_TOTAL: string
  INVOICE_NET_TOTAL: string
  INVOICE_CURRENCY: string
  ACCOUNT_TYPE: string
  LAW_FIRM_NAME: string
  LAW_FIRM_ADDRESS_1: string
  LAW_FIRM_ADDRESS_2: string
  LAW_FIRM_CITY: string
  LAW_FIRM_STATEorREGION: string
  LAW_FIRM_POSTCODE: string
  LAW_FIRM_COUNTRY: string
  INVOICE_REPORTED_TAX_TOTAL: string
  INVOICE_TAX_CURRENCY: string
}

export type LineItemHeader = {
  TIMEKEEPER_ID: string
  TIMEKEEPER_NAME: string
  TIMEKEEPER_LAST_NAME: string
  TIMEKEEPER_FIRST_NAME: string
  TIMEKEEPER_CLASSIFICATION: string
  "EXP/FEE/INV_ADJ_TYPE": string
  LINE_ITEM_UNIT_COST: string
  LINE_ITEM_NUMBER: number
  LINE_ITEM_NUMBER_OF_UNITS: string
  LINE_ITEM_ADJUSTMENT_AMOUNT: string
  LINE_ITEM_TOTAL: string
  LINE_ITEM_DATE: string
  LINE_ITEM_TASK_CODE: string
  LINE_ITEM_EXPENSE_CODE: string
  LINE_ITEM_ACTIVITY_CODE: string
  LINE_ITEM_DESCRIPTION: string
  LINE_ITEM_TAX_RATE: string
  LINE_ITEM_TAX_TOTAL: string
  LINE_ITEM_TAX_TYPE: string
}

// Define the function
export function generateLedesCsv(generalData: InvoiceData, lineItemData: LineItemHeader[]): Buffer {
  // Define headers
  const _headers = [
    "INVOICE_DATE",
    "INVOICE_NUMBER",
    "CLIENT_ID",
    "LAW_FIRM_MATTER_ID",
    "INVOICE_TOTAL",
    "BILLING_START_DATE",
    "BILLING_END_DATE",
    "INVOICE_DESCRIPTION",
    "LAW_FIRM_ID",
    "CLIENT_TAX_ID",
    "CLIENT_MATTER_ID",
    "CLIENT_NAME",
    "CLIENT_ADDRESS_1",
    "CLIENT_ADDRESS_2",
    "CLIENT_CITY",
    "CLIENT_STATEorREGION",
    "CLIENT_POSTCODE",
    "CLIENT_COUNTRY",
    "PO_NUMBER",
    "MATTER_NAME",
    "INVOICE_TAX_TOTAL",
    "INVOICE_NET_TOTAL",
    "INVOICE_CURRENCY",
    "ACCOUNT_TYPE",
    "LAW_FIRM_NAME",
    "LAW_FIRM_ADDRESS_1",
    "LAW_FIRM_ADDRESS_2",
    "LAW_FIRM_CITY",
    "LAW_FIRM_STATEorREGION",
    "LAW_FIRM_POSTCODE",
    "LAW_FIRM_COUNTRY",
    "INVOICE_REPORTED_TAX_TOTAL",
    "INVOICE_TAX_CURRENCY",
    ...Object.keys(lineItemData[0]),
  ]

  // Add LEDES98BI V2 header
  const ledesHeader = ["LEDES98BI V2"]

  const headers = _headers.sort((a, b) => {
    return SORTING_ORDER.indexOf(a) - SORTING_ORDER.indexOf(b)
  })

  // Combine headers and generalData
  const combinedData = lineItemData.map((lineItem) => ({
    ...generalData,
    ...lineItem,
  }))

  // Ensure the order of values matches the headers
  const orderedData = combinedData.map((item) =>
    headers.map((header) => {
      return item[header]
    })
  )

  // Convert generalData to CSV
  const csv = Papa.unparse([ledesHeader, headers, ...orderedData], {
    header: false,
    delimiter: "|",
    newline: "[]\r\n",
  })

  // Return CSV as Buffer
  return Buffer.from(csv, "utf8")
}
