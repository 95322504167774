import { PlusIcon } from "@heroicons/react/solid"
import { Menu, Text } from "@mantine/core"
import { openConfirmModal } from "@mantine/modals"
import { IconChartArea, IconCheck } from "@tabler/icons"
import { Button } from "antd"
import { entryStore } from "app/core/stores/TableStore"
import {
  lawyerStore,
  matterStore,
  modalStore,
  templateStore,
  whoAmIStore,
} from "app/core/stores/store"
import { theme } from "app/core/styles/styles"
import saveAs from "app/core/utils/save"
import getAllReviews from "app/reviews/queries/getAllReviews"
import getTemplates from "app/templates/queries/getTemplates"
import { invoke } from "blitz"
import { cloneDeep, isFinite, round } from "lodash"
import { observer } from "mobx-react-lite"
import moment from "moment"
import Papa from "papaparse"
import { useEffect, useState } from "react"
import Stats from "../Stats"
import { ButtonLoadingSpinner } from "../spinner/LoadingSpinner"
import { postEntry } from "../table/TDocketTable"
import getAllEntries from "app/entries/queries/getAllEntries"
import { formatHours } from "../table/cells/utility/time"

export const TNewEntryButton = observer((props: { onClick: any; onAdd }) => {
  useEffect(() => {
    invoke(getTemplates, {
      where: { userInOrganizationId: whoAmIStore.me.id },
    }).then((res) => {
      templateStore.setTemplates(res.templates)
    })
  }, [])

  const handlePostAll = () => {
    cloneDeep(entryStore.rows).forEach((row) => {
      postEntry(row, entryStore)
    })
  }

  const handleBillingsByYear = async () => {
    const byYear = async (year: moment.Moment) => {
      const pendingDockets = await invoke(getAllEntries, {
        where: {
          deleted: false,
          billingReviewId: null,
          userInOrganizationId: whoAmIStore.me.id,
          task: "bw",
          flag: false,
          date: {
            lte: moment(year).endOf("year").toDate(),
            gte: moment(year).startOf("year").toDate(),
          },
        },
      })

      const reviews = await invoke(getAllReviews, {
        where: {
          archived: false,
          pclawExport: null,
          finalizedTotal: { gt: 0 },
          arDate: {
            lte: moment(year).endOf("year").toDate(),
            gte: moment(year).startOf("year").toDate(),
          },
        },
      })

      const columns: string[] = []

      const rows = reviews.map((review) => {
        const breakdown = review.finalizedBreakdown as Record<string, any>

        const amounts = {}
        const hours = {}
        const rates = {}

        Object.keys(breakdown).forEach((key) => {
          const prefix = lawyerStore.findLawyerFromId(parseInt(key))?.shortName ?? "X"

          amounts["fees_" + prefix] = isFinite(breakdown[key].billableAmount)
            ? breakdown[key].billableAmount
            : 0

          const multiplier = review.feesBeforeTax! / review.finalizedSubtotal!
          amounts["discounted_fees_" + prefix] = isFinite(
            round(multiplier * breakdown[key].billableAmount, 2)
          )
            ? round(multiplier * breakdown[key].billableAmount, 2)
            : 0

          hours["hours_" + prefix] = isFinite(breakdown[key].billableHours)
            ? breakdown[key].billableHours
            : 0

          rates["rate_" + prefix] = isFinite(
            round(amounts["discounted_fees_" + prefix] / hours["hours_" + prefix], 2)
          )
            ? round(amounts["discounted_fees_" + prefix] / hours["hours_" + prefix], 2)
            : 0

          columns.push("fees_" + prefix)
          columns.push("discounted_fees_" + prefix)
          columns.push("hours_" + prefix)
          columns.push("rate_" + prefix)
        })

        return {
          invoice_no: review.invoiceNo,
          start: moment(review.entryRange[0]).format("YYYY-MM-DD"),
          end: moment(review.endDate).format("YYYY-MM-DD"),
          matter: matterStore.findMatterFromId(review.matterId)?.file,
          matter_description: matterStore.findMatterFromId(review.matterId)?.description,
          client: matterStore.findClientFromMatter(review.matterId)?.name,
          total: review.finalizedTotal,
          tax: review.finalizedTax,
          written_off: review.writtenOff,
          discount: review.discount,
          disbursements_before_tax: review.disbursementsBeforeTax,
          fees_before_tax_and_discount: review.finalizedSubtotal,
          hours: review.finalizedHours,
          ...amounts,
          ...hours,
          ...rates,
        }
      })

      const outputGen = (prefix: string) => {
        // Reduce `discounted_fees_` to a single value...
        const discountedFees = rows.reduce((acc, row) => {
          return (
            acc + (isFinite(row["discounted_fees_" + prefix]) ? row["discounted_fees_" + prefix] : 0)
          )
        }, 0)

        const billedFees = rows.reduce((acc, row) => {
          return acc + (isFinite(row["fees_" + prefix]) ? row["fees_" + prefix] : 0)
        }, 0)

        const billedHours = rows.reduce((acc, row) => {
          return acc + (isFinite(row["hours_" + prefix]) ? row["hours_" + prefix] : 0)
        }, 0)

        const discountedRate = round(discountedFees / billedHours, 2)

        const pendingBillableHours = pendingDockets.reduce((prev, curr) => prev + round(formatHours([curr]), 1), 0.0)

        const output = {
          lawyer: prefix,
          pendingBillableHours: pendingBillableHours.toFixed(1),
          billedHours: billedHours.toFixed(1),
          billedFeesBeforeDiscounts: discountedFees.toFixed(2),
          billedFeesWithDiscounts: billedFees.toFixed(2),
          billedEffectiveRate: discountedRate.toFixed(2),
          start: moment(year).startOf("year").format("YYYY-MM-DD"),
          end: moment(year).endOf("year").format("YYYY-MM-DD"),
        }
        return output
      }

      let output: any[] = []

      if (whoAmIStore.me.shortName === "ws" || whoAmIStore.me.shortName === "cs") {
        const allKeys = rows.reduce((keys, obj) => {
          // Get the keys of the current object
          const objKeys = Object.keys(obj)

          // Add only new keys to the keys set
          objKeys.forEach((key) => keys.add(key))

          return keys
        }, new Set<string>())

        // Convert the set to an array if needed
        const uniqueKeysArray: string[] = [...allKeys]

        Array.from(
          new Set(uniqueKeysArray.filter((key) => key.startsWith("discounted_fees_")))
        ).forEach((key) => {
          const prefix = key.replace("discounted_fees_", "")
          output.push(outputGen(prefix))
        })
      } else {
        const prefix = lawyerStore.findLawyerFromId(whoAmIStore.me.id)?.shortName ?? "X"
        output.push(outputGen(prefix))
      }

      const breakdownCsv = new Blob([Papa.unparse(output)], { type: "text/csv" })
      saveAs(breakdownCsv, `${moment(year).format("YYYY-MM-DD")}_billings_ytd.csv`)
    }

    await byYear(moment())
    await byYear(moment().subtract(1, "year"))
  }

  const handleClick = () => {
    modalStore.setContent(
      "Statistics",
      <div>
        <Stats></Stats>
      </div>,
      true
    )
  }

  return (
    <div>
      <div style={{ display: "flex", gap: 10 }}>
        <CreateButton text={"Add docket"} onAdd={props.onAdd} />
        <Button onClick={handleBillingsByYear}>Docket and Billing Report</Button>

        <Menu shadow="md" width={200}>
          <Menu.Target>
            <Button>More actions</Button>
          </Menu.Target>

          <Menu.Dropdown>
            <Menu.Item
              onClick={handleClick}
              leftSection={<IconChartArea color={theme.gray[700]} />}
            >
              Statistics
            </Menu.Item>
            <Menu.Item onClick={handlePostAll} leftSection={<IconCheck height={16} width={16} />}>
              Post all
            </Menu.Item>
          </Menu.Dropdown>
        </Menu>
      </div>
    </div>
  )
})

// export const TemplateButton = observer(
//   (props: { onSelect: (description, matterId, lawyerId) => any; templates: any }) => {
//     const { onSelect, templates } = props

//     const [deleteTemplateMutation] = useMutation(deleteTemplate)

//     const handleMenuClick = (e: any) => {
//       const longEnough = (templates.length ?? -1) > e.key
//       if (e.key !== undefined && longEnough) {
//         const entry: Entry = templates[e.key]
//         // TODO: Confirm matter and lawyer exist before adding...
//         onSelect(entry.description, entry.matterId ?? undefined, entry.lawyerId ?? undefined)
//       }
//     }

//     const menu = (
//       <Menu>
//         {templateStore.templates.map(
//           (e: { id: number; description: string; matterId: number; task: string }, i) => {
//             return (
//               <div key={i}>
//                 <SubMenu
//                   title={
//                     <span key={`${i}`} style={{ whiteSpace: "normal" }}>
//                       {e.description}
//                     </span>
//                   }
//                 >
//                   <Menu.Item onClick={() => handleMenuClick({ key: i })}>Use template</Menu.Item>
//                   <Menu.Item
//                     onClick={() => {
//                       templateStore.setTemplates(
//                         templateStore.templates.filter((t) => t.id !== e.id)
//                       )
//                       deleteTemplateMutation({ id: e.id })
//                     }}
//                   >
//                     Discard template
//                   </Menu.Item>
//                 </SubMenu>

//                 {i < props.templates.length - 1 ? <Menu.Divider /> : null}
//               </div>
//             )
//           }
//         )}
//       </Menu>
//     )

//     return (
//       <Dropdown overlay={menu}>
//         <Button>
//           Templates <DownOutlined />
//         </Button>
//       </Dropdown>
//     )
//   }
// )

export const CreateButton = (props: { onAdd: any; text: string }) => {
  const { text, onAdd } = props

  const [loading, setLoading] = useState(false)

  const onClick = () => {
    setLoading(true)
    setTimeout(() => {
      setLoading(false)
    }, 500)
    onAdd()
  }

  return (
    <Button
      disabled={loading}
      style={{
        marginBottom: 16,
        fontWeight: 400,
        display: "flex",
        alignItems: "center",
        gap: "4px",
      }}
      onClick={onClick}
    >
      <PlusIcon color={theme.gray[700]} height="18px" />
      {text}
      {loading ? <ButtonLoadingSpinner /> : null}
    </Button>
  )
}

export const ConfirmButton = ({ onConfirm, buttonText, confirmText, icon }) => {
  const openModal = () =>
    openConfirmModal({
      title: "Please confirm your action",
      children: <Text size="sm">{confirmText}</Text>,
      labels: { confirm: "Confirm", cancel: "Cancel" },
      onCancel: () => console.log("Cancel"),
      onConfirm,
    })

  return (
    <Button onClick={openModal} icon={icon}>
      {buttonText}
    </Button>
  )
}
