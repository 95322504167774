import {
  DownloadIcon,
  FilterIcon,
  InformationCircleIcon,
  TrashIcon,
  UploadIcon,
} from "@heroicons/react/solid"
import { Disbursement, Entry, ExportTemplate, Matter, Review } from "@prisma/client"
import { useHover } from "ahooks"
import {
  Button,
  Checkbox,
  Divider,
  Dropdown,
  Input,
  Menu,
  Select,
  Upload,
  notification,
} from "antd"
import getActivityCodes from "app/activity-codes/queries/getActivityCodes"
import { Section } from "app/core/components/Section"
import { LawyerSearch } from "app/core/components/input/LawyerSearch"
import { TClientSearch } from "app/core/components/input/TClientSearch"
import { TNavigation } from "app/core/components/layout/Navigation"
import { PageModal } from "app/core/components/modal/PageModal"
import { ButtonLoadingSpinner, LoadingSpinner } from "app/core/components/spinner/LoadingSpinner"
import { TTabBar, Tab } from "app/core/components/tab/TTabBar"
import { MatterTable } from "app/core/components/table/MatterTable"
import { sortEntriesByRateAndDate } from "app/core/components/table/TDocketTable"
import { formatHours } from "app/core/components/table/cells/utility/time"
import { TableStore } from "app/core/stores/TableStore"
import { lawyerStore, matterStore, modalStore, whoAmIStore } from "app/core/stores/store"
import { theme } from "app/core/styles/styles"
import { rateFromEntry } from "app/core/utils/billing"
import { saveAs } from "app/core/utils/save"
import { lawyerSummaries, totalsSummary } from "app/core/utils/word"
import getAllDisbursements from "app/disbursements/queries/getAllDisbursements"
import getAllEntries from "app/entries/queries/getAllEntries"
import getAllEntriesGroupBy from "app/entries/queries/getAllEntriesGroupBy"
import createExportTemplate from "app/export-templates/mutations/createExportTemplate"
import updateExportTemplate from "app/export-templates/mutations/updateExportTemplate"
import getExportTemplates from "app/export-templates/queries/getExportTemplates"
import getAllReviews from "app/reviews/queries/getAllReviews"
import getTaskCodes from "app/task-codes/queries/getTaskCodes"
import { BlitzPage, getAntiCSRFToken, invoke, useMutation } from "blitz"
import cuid from "cuid"
import createReport from "docx-templates"
import { capitalize, isEmpty, isNil, round, upperCase } from "lodash"
import { makeAutoObservable, runInAction } from "mobx"
import { observer } from "mobx-react-lite"
import moment from "moment"
import { PDFDocument } from "pdf-lib"
import {
  Suspense,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react"
import Box from "ui-box"
import { generateLedesCsv } from "../../../core/utils/ledes"
import { TRangePicker, defaultRanges } from "../reports"
import { matterPageStore } from "./[matter]"

const { Option } = Select

const matterFilterOptions: Readonly<
  {
    name: string
    title: string
    where: (value: any) => { [k: string]: any }
  }[]
> = [
  {
    name: "range",
    title: "Date Range",
    where: (value: Date[]) => {
      let date = { gte: value[0], lte: value[1] }
      return { date: date }
    },
  },
  {
    name: "lawyer",
    title: "Active lawyer",
    where: (value: number) => {
      return { lawyerId: value }
    },
  },
  {
    name: "client",
    title: "Active client",
    where: (value: number) => {
      return {
        matterId: {
          in: [...matterStore.matterArray.filter((f) => f.clientId === value).map((m) => m.id)],
        },
      }
    },
  },
  {
    name: "billing",
    title: "Billing status",
    where: (value: boolean) => {
      // TODO(C): Flag can be removed when we no longer sync.
      if (value === true) {
        return { OR: [{ billingReviewId: { not: null } }, { flag: true }] }
      } else if (value === false) {
        return { AND: [{ billingReviewId: null }, { flag: false }] }
      } else {
        return {}
      }
    },
  },
  {
    name: "responsible",
    title: "Responsible lawyer",
    where: (value: number) => {
      return { matter: { userInOrganizationId: value } }
    },
  },
] as const

type MatterFilterOptions = typeof matterFilterOptions[number]

type FilterType = Record<string, { active: boolean; value: any }>

class FilterStore {
  ranges = defaultRanges()

  filteredInfo: FilterType = {
    [matterFilterOptions[0].name]: {
      active: true,
      value: this.ranges[Object.keys(this.ranges)[0]],
    }, // Default filter date range.
  }

  setFilteredInfo(filteredInfo: FilterType) {
    this.filteredInfo = filteredInfo
  }

  constructor() {
    makeAutoObservable(this)
  }
}

const FilterStoreContext = createContext<FilterStore | undefined>(undefined)
const TableStoreContext = createContext<TableStore<Matter> | undefined>(undefined)

const ReportTable = observer(() => {
  const tableStore = useContext(TableStoreContext)!
  const filterStore = useContext(FilterStoreContext)!

  const where = Object.keys(filterStore.filteredInfo).reduce((prv, cur) => {
    const { active, value } = filterStore.filteredInfo[cur]
    if (active) {
      prv = { ...prv, ...matterFilterOptions.find((f) => f.name === cur)?.where(value) }
    }
    return prv
  }, {})

  useEffect(() => {
    invoke(getAllEntriesGroupBy, {
      by: ["matterId"],
      where,
      _sum: {
        timeInSeconds: true, // This value has already been rounded up to "tenth" of an hour.
      },
      _count: {
        matterId: true,
        billingReviewId: true,
        syncedAt: true,
      },
      orderBy: {
        _count: {
          matterId: "desc",
        },
      },
    }).then((res: any) => {
      tableStore.setRows(
        res.map((r) => {
          return {
            ...matterStore.findMatterFromId(r.matterId)!,
            timeInSeconds: r._sum.timeInSeconds,
            count: r._count.matterId,
            billedCount: r._count.billingReviewId,
            syncedCount: r._count.syncedAt,
          }
        })
      )
    })
  }, [tableStore, JSON.stringify(filterStore.filteredInfo)])

  return <MatterTable store={tableStore}></MatterTable>
})

const MattersPage: BlitzPage = () => {
  const [filterStore] = useState(new FilterStore())
  const [tableStore] = useState(() => new TableStore<Matter>())

  const tabs: Tab[] = [
    {
      name: "All",
      content: (
        <Suspense fallback={<LoadingSpinner center />}>
          <TableStoreContext.Provider value={tableStore}>
            <FilterStoreContext.Provider value={filterStore}>
              <ReportTable></ReportTable>
            </FilterStoreContext.Provider>
          </TableStoreContext.Provider>
        </Suspense>
      ),
    },
  ]

  return (
    <TNavigation
      buttons={
        <TableStoreContext.Provider value={tableStore}>
          <FilterStoreContext.Provider value={filterStore}>
            <CheckboxActions></CheckboxActions>
            <DateRangePicker></DateRangePicker>
            <FilterDropdown></FilterDropdown>
          </FilterStoreContext.Provider>
        </TableStoreContext.Provider>
      }
    >
      <PageModal />
      <TTabBar tabs={tabs} />
    </TNavigation>
  )
}

export const LinkButton = (props: { children; onClick }) => {
  const { children, onClick } = props
  return (
    <Box
      display="flex"
      alignItems="center"
      cursor="pointer"
      color={theme.blue[600]}
      fontWeight={500}
      fontSize={"14px"}
      height="32px"
      padding={"8px"}
      onClick={onClick}
    >
      {children}
    </Box>
  )
}

type CheckedTemplate = {
  id: number
  order: number
}

type ListItemProps = {
  checked: Matter[]
  item: Partial<ExportTemplate>
  onClick: (_finalize: boolean) => void
  checkedTemplates: CheckedTemplate[]
  onCheckChange: (templateId: number, checked: boolean) => void
  onOrderChange: (templateId: number, order: number) => void
}

const ListItem = (props: ListItemProps) => {
  const { checked, item, onClick, checkedTemplates, onCheckChange, onOrderChange } = props

  const [update] = useMutation(updateExportTemplate)

  const hoverRef = useRef<any>()
  const hover = useHover(hoverRef)

  const [loading, setLoading] = useState(false)

  const isChecked = checkedTemplates.some((t) => t.id === item.id)
  const orderNumber = checkedTemplates.find((t) => t.id === item.id)?.order

  return (
    <Box
      backgroundColor={hover ? theme.background[125] : "transparent"}
      ref={hoverRef}
      cursor={"pointer"}
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      paddingY={"12px"}
      paddingX={"6px"}
    >
      <Box display="flex" alignItems="center" gap="10px">
        <Checkbox
          checked={isChecked}
          onClick={(e) => {
            e.stopPropagation()
            onCheckChange(item.id!, !isChecked)
          }}
        />
        {isChecked && (
          <Input
            type="number"
            style={{ width: 60 }}
            value={orderNumber}
            onClick={(e) => e.stopPropagation()}
            onChange={(e) => {
              const value = parseInt(e.target.value)
              if (!isNaN(value)) {
                onOrderChange(item.id!, value)
              }
            }}
          />
        )}
        <Box display="flex" flexDirection="column">
          <Box fontWeight={500} color={theme.gray[1000]}>
            {item.name}
          </Box>
          <Box fontWeight={500} color={theme.gray[600]}>
            {lawyerStore.findLawyerFromId(item.userInOrganizationId!)?.invitedName ?? "Default"}
          </Box>
        </Box>
      </Box>
      {loading && <ButtonLoadingSpinner></ButtonLoadingSpinner>}
      <Box>
        {item.id && checked && (
          <Box display="flex" gap={"10px"}>
            <Button
              onClick={async (e) => {
                if (!loading) {
                  setLoading(true)
                  await onClick(false)
                  setLoading(false)
                }
              }}
            >
              <DownloadIcon height={16}></DownloadIcon>
            </Button>

            <Button
              onClick={async (e) => {
                e.stopPropagation()
                e.preventDefault()
                const data = await (await fetch(`/api/s3/download?key=${item.key}`)).blob()
                saveAs(data, item.name)
              }}
            >
              <InformationCircleIcon height={16}></InformationCircleIcon>
            </Button>
            <Button
              onClick={(e) => {
                e.stopPropagation()
                e.preventDefault()
                update({
                  id: item.id!,
                  archived: true,
                })
              }}
            >
              <TrashIcon height={16}></TrashIcon>
            </Button>
          </Box>
        )}
      </Box>
    </Box>
  )
}

function hasPrefix(name: string): boolean {
  // Define a list of common prefixes
  const prefixes = ["Mr", "Ms", "Mrs", "Miss", "Dr", "Prof", "Mx"]
  // Create a regex pattern for these prefixes
  const pattern = new RegExp(
    `^(?:${prefixes.map((prefix) => prefix.replace(".", "\\.")).join("|")})\\b`,
    "i"
  )
  // Check if the name starts with one of the prefixes
  return pattern.test(name)
}

const List = (props: { checked; results; review }) => {
  const { checked, results, review } = props
  const [checkedTemplates, setCheckedTemplates] = useState<CheckedTemplate[]>([])
  const [isExporting, setIsExporting] = useState(false)

  const handleCheckChange = (templateId: number, checked: boolean) => {
    if (checked) {
      setCheckedTemplates([
        ...checkedTemplates,
        { id: templateId, order: checkedTemplates.length + 1 },
      ])
    } else {
      setCheckedTemplates(checkedTemplates.filter((t) => t.id !== templateId))
    }
  }

  const handleOrderChange = (templateId: number, order: number) => {
    setCheckedTemplates(checkedTemplates.map((t) => (t.id === templateId ? { ...t, order } : t)))
  }

  const handleExportPDF = useCallback(async () => {
    try {
      setIsExporting(true)

      // Sort templates by order
      const orderedTemplates = [...checkedTemplates]
        .sort((a, b) => a.order - b.order)
        .map((t) => results.find((r) => r.id === t.id))

      const pdfDocs: ArrayBuffer[] = []

      // Convert each DOCX to PDF
      for (const template of orderedTemplates) {
        const docxBuffer = (await template.onClick(false)).buffer as Buffer // This gets the DOCX buffer

        const formData = new FormData()
        formData.append(
          "file",
          new Blob([docxBuffer], {
            type: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
          }),
          "document.docx"
        )

        const response = await fetch("https://v2.convertapi.com/convert/docx/to/pdf", {
          method: "POST",
          headers: {
            Authorization: "Bearer secret_SZCMKN21jUTm8l9T",
          },
          body: formData,
        })

        const jsonResponse = await response.json()

        // Convert base64 to array buffer
        const pdfBinary = atob(jsonResponse.Files[0].FileData)
        const pdfArray = new Uint8Array(pdfBinary.length)
        for (let i = 0; i < pdfBinary.length; i++) {
          pdfArray[i] = pdfBinary.charCodeAt(i)
        }
        const pdfBuffer = pdfArray.buffer

        pdfDocs.push(pdfBuffer)
      }

      // Merge PDFs
      const mergedPdf = await PDFDocument.create()

      for (const pdfDoc of pdfDocs) {
        const pdf = await PDFDocument.load(pdfDoc)
        const copiedPages = await mergedPdf.copyPages(pdf, pdf.getPageIndices())
        copiedPages.forEach((page) => mergedPdf.addPage(page))
      }

      const mergedPdfBytes = await mergedPdf.save()

      // Download merged PDF
      saveAs(new Blob([mergedPdfBytes]), `${review?.invoiceNo}.pdf`)
    } catch (error) {
      notification.error({
        message: "Failed to export PDF",
        description: error.message,
      })
    } finally {
      setIsExporting(false)
    }
  }, [checkedTemplates, results, review?.invoiceNo])

  return (
    <Box>
      {checkedTemplates.length > 0 && (
        <Box marginBottom="12px">
          <Button onClick={handleExportPDF} loading={isExporting}>
            Export as PDF ({checkedTemplates.length} selected)
          </Button>
        </Box>
      )}
      <Box marginTop={"-12px"}>
        {results.map((t) => (
          <ListItem
            checked={checked}
            onClick={async (finalize) => {
              const result = await t.onClick(finalize)
              saveAs(
                new Blob([result.buffer]),
                `${t.review?.invoiceNo}-${
                  matterStore.findMatterFromId(t.review.matterId)!.file
                }.docx`
              )
            }}
            item={t}
            key={t.id}
            checkedTemplates={checkedTemplates}
            onCheckChange={handleCheckChange}
            onOrderChange={handleOrderChange}
          />
        ))}
      </Box>
    </Box>
  )
}

interface GenerateReportResult {
  buffer: Buffer | null
  entriesMap: Array<
    Entry & {
      description: string
      formattedContact: string
      formattedDate: string
      MMMDYYYY: string
      formattedHours: string
      taskCode?: string
      activityCode?: string
      formattedRate: string
      formattedAmount: string
      formattedAmountCurrency: string
      formattedShortname: string
    }
  >
  discountAmount?: number
  writtenOffAmount: number
  disbursementsWithTax: Array<
    Disbursement & {
      amount: string
    }
  >
  disbursementsWithoutTax: Array<
    Disbursement & {
      amount: string
    }
  >
}

export const ExportTemplateSection = ({
  review, // Review must be a FINALIZED review.
  matters,
  startInput,
  endInput,
  disbursementIds,
}: {
  review?: Review
  matters?: Matter[]
  startInput?: Date
  endInput?: Date
  disbursementIds?: number[]
}) => {
  const [create] = useMutation(createExportTemplate)

  const antiCSRFToken = getAntiCSRFToken()

  const [results, setResults] = useState<ExportTemplate[]>()

  useEffect(() => {
    invoke(getExportTemplates, {}).then((res) => {
      setResults(res.exportTemplates)
    })
  }, [])

  if (!results) {
    return <LoadingSpinner center />
  }

  const invoiceNo = review?.invoiceNo ?? "UNDEFINED"
  const start = review?.entryRange?.[0] ?? startInput
  const end = review?.entryRange?.[1] ?? endInput

  const generateReport = async (
    key: string | null,
    entries: Entry[],
    disbursements: Disbursement[],
    matter: Matter,
    invoiceNo: number,
    previousBalance: number
  ) => {
    const taskCodes = await invoke(getTaskCodes, {})
    const activityCodes = await invoke(getActivityCodes, {})

    const _lawyerSummaries = lawyerSummaries(entries)

    let discount = 0.0
    if (review?.finalized) {
      discount = review?.discount ?? 0.0
    } else {
      discount = matter.discount ?? 0.0
    }

    let writtenOff = 0.0
    if (review?.finalized) {
      writtenOff = review?.writtenOff ?? 0.0
    }

    const _totalSummary = totalsSummary(
      matter,
      entries,
      disbursements,
      discount,
      previousBalance,
      writtenOff,
      review?.trustApplied ?? 0.0
    )

    const totalSummary = {}

    const usdRateResponse: { value: number } = await (
      await fetch(
        "https://api.currencybeacon.com/v1/convert?" +
          new URLSearchParams({
            api_key: "qc6sgbEopWuL78PRbUrL2gG1WWcrHJrH",
            from: "CAD",
            to: "USD",
            amount: "1",
          }),
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
    ).json()

    const usdRate = round(usdRateResponse.value + 0.01, 3)
    totalSummary["usdRate"] = usdRate
    _totalSummary["usdTotal"] = round(_totalSummary.total * usdRate, 2)

    const eurRateResponse: { value: number } = await (
      await fetch(
        "https://api.currencybeacon.com/v1/convert?" +
          new URLSearchParams({
            api_key: "qc6sgbEopWuL78PRbUrL2gG1WWcrHJrH",
            from: "CAD",
            to: "EUR",
            amount: "1",
          }),
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
    ).json()

    const eurRate = round(eurRateResponse.value + 0.01, 3)
    totalSummary["eurRate"] = eurRate
    _totalSummary["eurTotal"] = round(_totalSummary.total * eurRate, 2)

    Object.keys(_totalSummary).forEach((t) => {
      if (t === "eurTotal") {
        totalSummary[t] = matterPageStore.currencyLocaleFunction(_totalSummary[t], "EUR")
      } else if (t !== "hours") {
        totalSummary[t] = matterPageStore.currencyLocaleFunction(_totalSummary[t])
      } else {
        totalSummary[t] = _totalSummary[t].toFixed(1) as any
      }
    })

    const summaries = {
      ...totalSummary,
      lawyers: _lawyerSummaries.map((ls) => ({
        ...ls,
        amount: matterPageStore.currencyLocaleFunction(ls.amount),
        hours: ls.hours.toFixed(2),
        rate: ls.rate.toFixed(2),
      })),
    }

    let reLine = matter.reLine

    if (!reLine) {
      reLine = `${matter.description}
GL: ${matter.glLine ?? ""}
CC:
IO:
Court File No.: ${matter.courtFileNumber ?? ""}
Our File No.: ${matter.file}
`
    }

    const client = matterStore.findClientFromMatter(matter.id!)!

    let address = client.address

    if (!address || isEmpty(address)) {
      address = `${client.street}
${client.city}, ${client.province}
${client.postalCode}
`
    }

    const mtd = await invoke(getAllReviews, {
      where: {
        pclawExport: null,
        matterId: matter.id,
        finalized: true,
        archived: false,
        arDate: {
          // TODO: Is this correct to use arDate?
          lte: review?.arDate ?? moment().endOf("day").toDate(),
        },
        id: {
          not: review?.id ?? -1,
        },
      },
    })

    const ytd = mtd.filter((m) => {
      // TODO: Is this correct to use arDate?
      return m.arDate?.getFullYear() === new Date().getFullYear()
    })

    const ytdFeesAfterThisInvoiceBeforeTax =
      ytd.reduce((acc, cur) => {
        return (acc += cur.feesBeforeTax ?? 0.0)
      }, 0.0) + _totalSummary.net

    const ytdFeesAndDisbursementsAfterThisInvoiceBeforeTax =
      ytd.reduce((acc, cur) => {
        return (acc += (cur.feesBeforeTax ?? 0.0) + (cur.disbursementsBeforeTax ?? 0.0))
      }, 0.0) + _totalSummary.beforeTaxTotal

    const mtdFeesAfterThisInvoiceBeforeTax =
      mtd.reduce((acc, cur) => {
        return (acc += cur.feesBeforeTax ?? 0.0)
      }, 0.0) +
      _totalSummary.net +
      (matter.startingFeesBalance ?? 0.0)

    const mtdFeesAndDisbBeforeThisInvBfrTax =
      mtd.reduce((acc, cur) => {
        return (acc += (cur.feesBeforeTax ?? 0.0) + (cur.disbursementsBeforeTax ?? 0.0))
      }, 0.0) +
      (matter.startingFeesBalance ?? 0.0) +
      (matter.startingDisbursementsBalance ?? 0.0)

    const mtdFeesAndDisbursementsAfterThisInvoiceBeforeTax =
      mtdFeesAndDisbBeforeThisInvBfrTax + _totalSummary.beforeTaxTotal

    const _budgetRemainAfterInvBfrTax =
      (matter.budget ?? 0.0) - mtdFeesAndDisbursementsAfterThisInvoiceBeforeTax
    const budgetRemainAfterInvBfrTax = `${
      _budgetRemainAfterInvBfrTax < 0 ? "-" : ""
    }${matterPageStore.currencyLocaleFunction(Math.abs(_budgetRemainAfterInvBfrTax))}`

    const contactNameArr = client.contactName.split(" ")

    if (hasPrefix(client.contactName) && client.contactName.split(" ").length === 3) {
      contactNameArr.splice(1, 1)
    }

    const entriesMap = entries
      .sort(sortEntriesByRateAndDate(lawyerStore.lawyersArray))
      .map((e) => ({
        ...e,
        description: e.description?.trim(),
        formattedContact: matterStore.findClientFromMatter(e.matterId!)!.contactName?.trim(),
        formattedDate: moment(e.date).format("MMM-DD-YY"),
        MMMDYYYY: moment(e.date).format("MMM. D, YYYY"),
        formattedHours: formatHours([e]).toFixed(1),
        taskCode: taskCodes?.taskCodes.find((t) => t.id === e.taskCodeId)?.code,
        activityCode: activityCodes?.activityCodes.find((a) => a.id === e.activityCodeId)?.code,
        formattedRate: _lawyerSummaries.find((l) => l.lawyer === e.lawyerId)!.rate.toFixed(2),
        formattedAmount: round(formatHours([e]) * rateFromEntry(e), 2).toFixed(2),
        formattedAmountCurrency: matterPageStore.currencyLocaleFunction(
          round(formatHours([e]) * rateFromEntry(e), 2)
        ),
        formattedShortname: upperCase(lawyerStore.findLawyerFromId(e.lawyerId)?.shortName),
      }))

    const sortDisbursements = (disbursements: Disbursement[]) => {
      return disbursements.sort((a, b) => {
        return (
          (a.dateServiceRendered?.getTime() ?? a.date?.getTime()) -
          (b.dateServiceRendered?.getTime() ?? b.date?.getTime())
        )
      })
    }

    const disbursementsWithoutTax = sortDisbursements(
      disbursements
        .filter((d) => isNil(d.tax) || d.tax <= 0)
        .map((d) => {
          return {
            ...d,
            amount: matterPageStore.currencyLocaleFunction(d.amount) as any,
          }
        })
    )

    const disbursementsWithTax = sortDisbursements(
      disbursements
        .filter((d) => (d.tax ?? 0) > 0)
        .map((d) => {
          return {
            ...d,
            amount: matterPageStore.currencyLocaleFunction(d.amount) as any,
          }
        })
    )

    let report: any = null
    if (key) {
      const data = await (await fetch(`/api/s3/download?key=${key}`)).blob()

      report = await createReport({
        data: {
          ytdFeesAfterThisInvoiceBeforeTax: matterPageStore.currencyLocaleFunction(
            ytdFeesAfterThisInvoiceBeforeTax
          ),
          ytdFeesAndDisbursementsAfterThisInvoiceBeforeTax: matterPageStore.currencyLocaleFunction(
            ytdFeesAndDisbursementsAfterThisInvoiceBeforeTax
          ),
          mtdFeesAfterThisInvoiceBeforeTax: matter.budget
            ? matterPageStore.currencyLocaleFunction(mtdFeesAfterThisInvoiceBeforeTax)
            : "No budget and starting balances set",
          mtdFeesAndDisbursementsAfterThisInvoiceBeforeTax: matter.budget
            ? matterPageStore.currencyLocaleFunction(
                mtdFeesAndDisbursementsAfterThisInvoiceBeforeTax
              )
            : "No budget and starting balances set",
          mtdFeesAndDisbBeforeThisInvBfrTax: matter.budget
            ? matterPageStore.currencyLocaleFunction(mtdFeesAndDisbBeforeThisInvBfrTax)
            : "No budget and starting balances set",
          budgetRemainAfterInvBfrTax: matter.budget
            ? budgetRemainAfterInvBfrTax
            : "No budget and starting balances set",
          budget: matter.budget ?? 0.0,
          budgetFormatted: matterPageStore.currencyLocaleFunction(matter.budget ?? 0.0),
          writtenOff,
          discount,
          trustApplied: review?.trustApplied ?? 0.0,
          formattedTrustApplied: matterPageStore.currencyLocaleFunction(
            review?.trustApplied ?? 0.0
          ),
          taxNumber: whoAmIStore.me.organization.taxNumber,
          invoiceNo,
          summaries,
          periodStart: moment(start).format("MMMM D, YYYY"),
          periodEnd: moment(end).format("MMMM D, YYYY"),
          today: moment().format("MMMM D, YYYY"),
          formattedToday: moment().format("MMM DD/YY"),
          client: {
            ...client,
            contactName: contactNameArr.join(" "),
            address,
          },
          entries: entriesMap,
          disbursements: sortDisbursements(
            disbursements.map((d) => {
              return {
                ...d,
                amount: matterPageStore.currencyLocaleFunction(d.amount) as any,
              }
            })
          ),
          disbursementsWithoutTax,
          disbursementsWithTax,
          matter: {
            ...matter,
            attnLine: (
              matter.attnLine ?? matterStore.findClientFromMatter(matter.id!)?.contactName
            )?.trim(),
            reLine: reLine?.trim(),
          },
          previousBalance,
        },
        template: (await data.arrayBuffer()) as any,
        cmdDelimiter: ["{", "}"],
        literalXmlDelimiter: "||",
        processLineBreaks: true,
        noSandbox: false,
        failFast: true,
        /**
         * When set to `true`, this setting ensures createReport throws an error when the result of an INS, HTML, IMAGE, or LINK command turns out to be null or undefined,
         * as this usually indicates a mistake in the template or the invoking code (defaults to `false`).
         */
        rejectNullish: false,
        /**
         * MS Word usually autocorrects JS string literal quotes with unicode 'smart' quotes ('curly' quotes). E.g. 'aubergine' -> ‘aubergine’.
         * This causes an error when evaluating commands containing these smart quotes, as they are not valid JavaScript.
         * If you set fixSmartQuotes to 'true', these smart quotes will automatically get replaced with straight quotes (') before command evaluation.
         * Defaults to false.
         */
        fixSmartQuotes: false,
      })
    }

    return {
      buffer: report ? await Buffer.from(report) : null,
      entriesMap,
      discountAmount: _totalSummary.discount,
      writtenOffAmount: _totalSummary.writtenOff,
      disbursementsWithTax,
      disbursementsWithoutTax,
    }
  }

  const exportReport = async (
    key: string | null,
    matterId: number,
    _finalize
  ): Promise<GenerateReportResult> => {
    let entryWhere = {}
    if (review && review.finalized) {
      entryWhere = {
        billingReviewId: review.id,
        deleted: false,
      }
    } else if (review && !review.finalized) {
      entryWhere = {
        prebillingReviewId: review.id,
        deleted: false,
      }
    } else {
      entryWhere = {
        matterId,
        date: {
          gte: start,
          lte: end,
        },
        deleted: false,
      }
    }

    const entries = await invoke(getAllEntries, {
      where: entryWhere,
      orderBy: {
        date: "asc",
      },
    })

    let disbWhere = {}

    if (!review) {
      disbWhere = {
        matterId,
        billingReviewId: null,
        deleted: false,
        estimate: false,
      }
      if (disbursementIds) {
        disbWhere = {
          ...disbWhere,
          id: {
            in: disbursementIds,
          },
        }
      }
    } else if (review && review.finalized) {
      disbWhere = {
        billingReviewId: review.id,
        deleted: false,
      }
    } else if (review && !review.finalized) {
      disbWhere = {
        prebillingReviewId: review.id,
        deleted: false,
      }
    }

    const disbursements = await invoke(getAllDisbursements, {
      where: disbWhere,
      orderBy: {
        date: "asc",
      },
    })

    const result = await generateReport(
      key,
      entries,
      disbursements,
      matterStore.findMatterFromId(matterId)!,
      parseInt(invoiceNo as any),
      0.0 // Are we putting previous balance in here?
    )
    return result as any
  }

  const exportLedesReport = async (matterId, _finalize) => {
    const result = await exportReport(null, matterId, _finalize)

    const client = matterStore.findClientFromMatter(matterId)!
    const matter = matterStore.findMatterFromId(matterId)!
    const generalData = {
      INVOICE_DATE: moment(review?.arDate).format("YYYYMMDD"),
      INVOICE_NUMBER: invoiceNo?.toString(),
      // Revisit
      CLIENT_ID: "409",
      // Remove all A-Z
      LAW_FIRM_MATTER_ID: matter.file.replaceAll(/[A-Z]/g, ""),
      INVOICE_TOTAL: round(review?.finalizedTotal ?? 0, 2)?.toString(),
      BILLING_START_DATE: moment(start).format("YYYYMMDD"),
      BILLING_END_DATE: moment(end).format("YYYYMMDD"),
      INVOICE_DESCRIPTION: "",
      LAW_FIRM_ID: "272371638",
      CLIENT_TAX_ID: "AAACD7999Q",
      CLIENT_MATTER_ID: "1366769",
      CLIENT_NAME: "",
      CLIENT_ADDRESS_1: "",
      CLIENT_ADDRESS_2: "",
      CLIENT_CITY: "",
      CLIENT_STATEorREGION: "",
      CLIENT_POSTCODE: "",
      CLIENT_COUNTRY: "USA",
      PO_NUMBER: "",
      MATTER_NAME: `${matter.description}_CA (Sprigings)`,
      INVOICE_TAX_TOTAL: round(
        (review?.feesTax ?? 0) + (review?.disbursementsTax ?? 0),
        2
      )?.toString(),
      INVOICE_NET_TOTAL: round(
        (review?.feesBeforeTax ?? 0.0) + (review?.disbursementsBeforeTax ?? 0.0),
        2
      )?.toString(),
      INVOICE_CURRENCY: "CAD",
      ACCOUNT_TYPE: "O",
      LAW_FIRM_NAME: "Sprigings Law",
      LAW_FIRM_ADDRESS_1: "148 Norseman Street",
      LAW_FIRM_ADDRESS_2: "",
      LAW_FIRM_CITY: "Toronto",
      LAW_FIRM_STATEorREGION: "ON",
      LAW_FIRM_POSTCODE: "M8Z 2R4",
      LAW_FIRM_COUNTRY: "CAN",
      INVOICE_REPORTED_TAX_TOTAL: round(
        (review?.feesTax ?? 0) + (review?.disbursementsTax ?? 0),
        2
      )?.toString(),
      INVOICE_TAX_CURRENCY: "CAD",
    }

    const invoiceData = result.entriesMap
      .map(
        (
          e: Entry & {
            formattedContact: string
            formattedDate: string
            MMMDYYYY: string
            formattedHours: string
            taskCode?: string
            activityCode?: string
            formattedRate: string
            formattedAmount: string
            formattedAmountCurrency: string
            formattedShortname?: string
          },
          i
        ) => ({
          TIMEKEEPER_ID: (e.formattedShortname ?? "").toUpperCase().replaceAll("CVH", "CH"),
          TIMEKEEPER_NAME: lawyerStore.findLawyerFromId(e.lawyerId)!.invitedName,
          TIMEKEEPER_LAST_NAME: lawyerStore.findLawyerFromId(e.lawyerId)!.invitedName.split(" ")[1],
          TIMEKEEPER_FIRST_NAME: lawyerStore
            .findLawyerFromId(e.lawyerId)!
            .invitedName.split(" ")[0],
          // Revisit
          TIMEKEEPER_CLASSIFICATION: ["mw", "ckw", "dz"].includes(
            lawyerStore.findLawyerFromId(e.lawyerId)!.shortName
          )
            ? "SE"
            : ["ws", "dr"].includes(lawyerStore.findLawyerFromId(e.lawyerId)!.shortName)
            ? "PT"
            : "AS",
          "EXP/FEE/INV_ADJ_TYPE": "F",
          LINE_ITEM_UNIT_COST: e.formattedRate,
          LINE_ITEM_NUMBER: i + 1,
          LINE_ITEM_NUMBER_OF_UNITS: e.formattedHours,
          LINE_ITEM_ADJUSTMENT_AMOUNT: "0.00",
          LINE_ITEM_TOTAL: e.formattedAmount,
          LINE_ITEM_DATE: moment(e.date).format("YYYYMMDD"),
          LINE_ITEM_TASK_CODE: e.taskCode ?? "",
          LINE_ITEM_EXPENSE_CODE: "",
          LINE_ITEM_ACTIVITY_CODE: e.activityCode ?? "",
          LINE_ITEM_DESCRIPTION: e.description?.replaceAll("|", " ") ?? "",
          // Revisit
          LINE_ITEM_TAX_RATE: (review?.feesTax ?? 0) > 1 ? "0.13" : "0",
          // Revisit
          LINE_ITEM_TAX_TOTAL:
            (review?.feesTax ?? 0) > 1
              ? round(parseFloat(e.formattedAmount) * 0.13, 2)?.toString()
              : "0",
          LINE_ITEM_TAX_TYPE: (review?.feesTax ?? 0) > 1 ? "HST" : "0",
        })
      )
      .concat(
        result.disbursementsWithTax
          .concat(result.disbursementsWithoutTax)
          .map((d: Disbursement, j) => {
            const taxRate = d.tax ?? 0
            const timekeeper =
              lawyerStore.findLawyerFromId(client.userInOrganizationId) ??
              lawyerStore.findLawyerFromId(d.userInOrganizationId)!

            return {
              TIMEKEEPER_ID: "",
              TIMEKEEPER_NAME: "",
              TIMEKEEPER_LAST_NAME: "",
              TIMEKEEPER_FIRST_NAME: "",
              TIMEKEEPER_CLASSIFICATION: "",
              "EXP/FEE/INV_ADJ_TYPE": "E",
              // Revisit
              LINE_ITEM_UNIT_COST: d.amount.toFixed(2),
              LINE_ITEM_NUMBER: (result?.entriesMap?.length ?? 0) + j + 1,
              LINE_ITEM_NUMBER_OF_UNITS: "1",
              LINE_ITEM_ADJUSTMENT_AMOUNT: "0.00",
              LINE_ITEM_TOTAL: round(d.amount + (d.tax ?? 0), 2).toFixed(2),
              LINE_ITEM_DATE: moment(d.date).format("YYYYMMDD"),
              LINE_ITEM_TASK_CODE: "",
              // Revisit
              LINE_ITEM_EXPENSE_CODE: "E106",
              LINE_ITEM_ACTIVITY_CODE: "",
              LINE_ITEM_DESCRIPTION: d.reason?.replaceAll("|", " ") ?? "",
              LINE_ITEM_TAX_RATE: (d.tax ?? 0) > 0 ? "0.13" : "",
              LINE_ITEM_TAX_TOTAL: round(d.tax ?? 0, 2)?.toString(),
              LINE_ITEM_TAX_TYPE: (d.tax ?? 0) > 0 ? "HST" : "",
            }
          })
      )

    // Revisit: add write offs as well

    let lineItemNumber =
      (result?.entriesMap?.length ?? 0) +
      result.disbursementsWithTax.concat(result.disbursementsWithoutTax).length +
      1

    if (result.writtenOffAmount) {
      invoiceData.push({
        TIMEKEEPER_ID: "",
        TIMEKEEPER_NAME: "",
        TIMEKEEPER_LAST_NAME: "",
        TIMEKEEPER_FIRST_NAME: "",
        TIMEKEEPER_CLASSIFICATION: "",
        "EXP/FEE/INV_ADJ_TYPE": "IF",
        LINE_ITEM_UNIT_COST: "",
        LINE_ITEM_NUMBER: ++lineItemNumber,
        LINE_ITEM_NUMBER_OF_UNITS: "0",
        LINE_ITEM_ADJUSTMENT_AMOUNT: `${(0 - result.writtenOffAmount).toFixed(2)}`,
        LINE_ITEM_TOTAL: `${(0 - result.writtenOffAmount).toFixed(2)}`,
        LINE_ITEM_DATE: moment(end).format("YYYYMMDD"),
        LINE_ITEM_TASK_CODE: "",
        LINE_ITEM_EXPENSE_CODE: "",
        LINE_ITEM_ACTIVITY_CODE: "",
        LINE_ITEM_DESCRIPTION: "Adjustment",
        LINE_ITEM_TAX_RATE: "",
        LINE_ITEM_TAX_TOTAL: "0.00",
        LINE_ITEM_TAX_TYPE: "",
      })
    }

    if (result.discountAmount) {
      invoiceData.push({
        TIMEKEEPER_ID: "",
        TIMEKEEPER_NAME: "",
        TIMEKEEPER_LAST_NAME: "",
        TIMEKEEPER_FIRST_NAME: "",
        TIMEKEEPER_CLASSIFICATION: "",
        "EXP/FEE/INV_ADJ_TYPE": "IF",
        LINE_ITEM_UNIT_COST: "",
        LINE_ITEM_NUMBER: ++lineItemNumber,
        LINE_ITEM_NUMBER_OF_UNITS: "0",
        LINE_ITEM_ADJUSTMENT_AMOUNT: `${(0 - result.discountAmount).toFixed(2)}`,
        LINE_ITEM_TOTAL: `${(0 - result.discountAmount).toFixed(2)}`,
        LINE_ITEM_DATE: moment(end).format("YYYYMMDD"),
        LINE_ITEM_TASK_CODE: "",
        LINE_ITEM_EXPENSE_CODE: "",
        LINE_ITEM_ACTIVITY_CODE: "",
        LINE_ITEM_DESCRIPTION:
          matterStore.findClientFromMatter(matterId)?.id === 413
            ? "Discount if payment is received within 60 days of invoice date"
            : "Discount",
        LINE_ITEM_TAX_RATE: "",
        LINE_ITEM_TAX_TOTAL: "0.00",
        LINE_ITEM_TAX_TYPE: "",
      })
    }

    const output = generateLedesCsv(generalData, invoiceData)
    saveAs(
      new Blob([output]),
      `LEDES_${review?.invoiceNo}-${matterStore.findMatterFromId(matterId)!.file}.txt`
    )
  }

  return (
    <>
      <Section
        title={"Custom"}
        button={
          <Box display="flex" gap="10px">
            {review?.matterId && matterStore.findClientFromMatter(review?.matterId)?.id === 413 && (
              <Button
                onClick={() => {
                  exportLedesReport(review?.matterId, false)
                }}
                icon={<DownloadIcon height="16px"></DownloadIcon>}
              >
                LEDES
              </Button>
            )}
            <Upload
              showUploadList={false}
              accept=".docx"
              name={"file"}
              headers={{
                "anti-csrf": antiCSRFToken,
              }}
              action={async (file) => {
                const uid = cuid()
                const res = await fetch(`/api/s3/presign?key=${uid}`, {
                  headers: { "content-type": "application/xml" },
                })
                const { url, fields } = await res.json()
                const formData = new FormData()
                Object.entries({ ...fields, file }).forEach(([key, value]) => {
                  formData.append(key, value as string | Blob)
                })
                await fetch(url, {
                  method: "POST",
                  body: formData,
                })
                create({ name: file.name, key: uid }).then((res) => {
                  results.push(res)
                })
                return "#"
              }}
              onChange={(info) => {
                if (info.file.status !== "uploading") {
                  console.log(info.file, info.fileList)
                }
                if (info.file.status === "done") {
                  notification.success({
                    message: `${info.file.name} file uploaded successfully`,
                  })
                } else if (info.file.status === "error") {
                  notification.error({ message: `${info.file.name} file upload failed.` })
                }
              }}
            >
              <Button icon={<UploadIcon height="16px"></UploadIcon>}>Upload template</Button>
            </Upload>
          </Box>
        }
      >
        <List
          review={review}
          checked={true}
          results={results.map((r) => {
            return {
              ...r,
              review,
              onClick: (_finalize) => {
                if (review) {
                  return exportReport(r.key, review.matterId, _finalize)
                }
              },
            }
          })}
        ></List>
      </Section>
    </>
  )
}

const CheckboxActions = observer((props: {}) => {
  const tableStore = useContext(TableStoreContext)!
  const filterStore = useContext(FilterStoreContext)!

  if (tableStore.checkedRows.length === 0) {
    return null
  }

  return (
    <Box display="flex" alignItems="center" gap={"10px"}>
      <Box width={"max-content"} display="flex" color={theme.gray[600]}>
        {`${tableStore.checkedRows.length} selected`}
      </Box>
      <Divider type="vertical"></Divider>
      <LinkButton
        onClick={() => {
          tableStore.setCheckedRows([])
        }}
      >
        Deselect
      </LinkButton>
      <Button
        disabled={!filterStore.filteredInfo["range"].active}
        onClick={() => {
          modalStore.setContent(
            "Export options",
            <Box paddingX={"24px"} paddingBottom={"24px"}>
              <ExportTemplateSection
                matters={tableStore.checkedRows}
                startInput={filterStore.filteredInfo["range"].value[0]}
                endInput={filterStore.filteredInfo["range"].value[1]}
              ></ExportTemplateSection>
            </Box>,
            true
          )
        }}
        icon={<DownloadIcon height="16px"></DownloadIcon>}
      >
        Export
      </Button>
    </Box>
  )
})

const DateRangePicker = observer((props: {}) => {
  const store = useContext(FilterStoreContext)!
  const tableStore = useContext(TableStoreContext)!

  const value = store.filteredInfo[matterFilterOptions[0].name]

  if (tableStore.checkedRows.length > 0) {
    return null
  }

  return (
    <TRangePicker
      onChange={(dateRange) => {
        runInAction(() => {
          store.filteredInfo[matterFilterOptions[0].name] = { active: true, value: dateRange }
        })
      }}
      value={value?.["active"] ? value?.["value"] : undefined}
    ></TRangePicker>
  )
})

const FilterCollapse = (props: { children }) => {
  const { children } = props
  return <Box>{children}</Box>
}

const FilterItem = observer((props: { onClick; value: MatterFilterOptions }) => {
  const { onClick, value } = props

  const store = useContext(FilterStoreContext)!
  const checked = store.filteredInfo[value.name]?.["active"] === true
  const selection = store.filteredInfo[value.name]?.["value"]

  return (
    <Box>
      <Box
        cursor={"pointer"}
        onClick={(e) => {
          onClick(value)
        }}
        borderTop={"1px solid"}
        borderTopColor={theme.gray[350]}
        padding={10}
        alignItems="center"
        display="flex"
        gap={10}
      >
        <Checkbox checked={checked} />
        <Box>{capitalize(value.title)}</Box>
      </Box>
      {checked && (
        <Box
          backgroundColor={theme.background[125]}
          padding={10}
          borderTop={"1px solid"}
          borderTopColor={theme.gray[350]}
        >
          {value.name === matterFilterOptions[0].name && (
            <TRangePicker
              onChange={(dateRange) => {
                runInAction(() => {
                  store.filteredInfo[value.name] = { value: dateRange, active: true }
                })
              }}
              value={store.filteredInfo[value.name]?.["value"]}
            ></TRangePicker>
          )}

          {(value.name === matterFilterOptions[1].name ||
            value.name === matterFilterOptions[4].name) && (
            <FilterCollapse>
              <Box paddingRight={"4px"}>
                <LawyerSearch
                  defaultValue={store.filteredInfo[value.name]["value"]}
                  onSelect={(id: number) => {
                    runInAction(() => {
                      store.filteredInfo[value.name]["value"] = id
                    })
                  }}
                  formProps={{}}
                />
              </Box>
            </FilterCollapse>
          )}

          {value.name === matterFilterOptions[2].name && (
            <FilterCollapse>
              <Box paddingRight={"4px"}>
                <TClientSearch
                  initialClientId={store.filteredInfo[value.name]["value"]}
                  onSelect={(id: number) => {
                    runInAction(() => {
                      store.filteredInfo[value.name]["value"] = id
                    })
                  }}
                  formProps={{}}
                />
              </Box>
            </FilterCollapse>
          )}

          {value.name === matterFilterOptions[3].name && (
            <FilterCollapse>
              <Select
                style={{ width: "100%" }}
                defaultValue={store.filteredInfo[value.name]["value"] ?? undefined}
                onChange={(v) => {
                  runInAction(() => {
                    store.filteredInfo[value.name]["value"] = v
                  })
                }}
              >
                <Option value={false}>Unbilled dockets (and unsynced)</Option>
                <Option value={true}>Billed dockets (or synced)</Option>
              </Select>
            </FilterCollapse>
          )}
        </Box>
      )}
    </Box>
  )
})

const FilterCount = observer(() => {
  const store = useContext(FilterStoreContext)!

  const count = Object.keys(store.filteredInfo ?? {}).reduce((acc, key) => {
    return acc + (store.filteredInfo[key]?.["active"] === true ? 1 : 0)
  }, 0)
  if (count > 0) {
    return (
      <>
        <div
          style={{
            position: "relative",
            display: "block",
            width: "0.5px",
            height: "32px",
            backgroundColor: theme.gray[300],
            marginLeft: 2,
            marginRight: 2,
          }}
        />
        <div style={{ color: theme.gray[700] }}>{count}</div>
      </>
    )
  } else {
    return null
  }
})

const FilterDropdown = observer((props) => {
  const store = useContext(FilterStoreContext)!
  const [filters] = useState(matterFilterOptions)

  const tableStore = useContext(TableStoreContext)!

  const onClick = useCallback((v: MatterFilterOptions) => {
    runInAction(() => {
      store.filteredInfo[v.name] = {
        ...(store.filteredInfo[v.name] ?? {}),
        active: (store.filteredInfo[v.name] ?? {})?.["active"] === true ? false : true,
      }
    })
  }, [])

  const menu = (
    <Menu style={{ minWidth: "300px" }}>
      {filters.map((filter, i) => {
        return (
          <Box key={i} style={{ borderRadius: 0, padding: 0 }}>
            <FilterItem onClick={onClick} value={filter} />
          </Box>
        )
      })}
    </Menu>
  )

  if (tableStore.checkedRows.length > 0) {
    return null
  }

  return (
    <Dropdown overlay={menu} placement={"bottomRight"} trigger={["click"]}>
      <Button
        icon={<FilterIcon color={theme.gray[700]} height="18px" />}
        style={{ display: "flex", alignItems: "center", gap: "4px" }}
      >
        Filter
        <FilterCount />
      </Button>
    </Dropdown>
  )
})

export default MattersPage
